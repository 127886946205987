/**@jsx jsx  */
import { Fragment, useContext } from "react";
import { Heading, jsx } from "theme-ui";
import Img from "gatsby-image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { RightArrow, DownArrow } from "../../icons";
import { AppContext } from "../../context/app-context";

interface ServiceComponentProps {
  services: any;
  category?: boolean;
  handleClickService: (any) => void;
}

export function ServiceComponent({
  services,
  category,
  handleClickService,
}: ServiceComponentProps) {
  const { serviceId } = useContext(AppContext);
  return (
    <Fragment>
      {services.map((service) => {
        const description = documentToReactComponents(service.description.json);
        return (
          <Fragment key={service.id}>
            <div sx={{ display: ["block", "none"] }}>
              <div
                id={service.slug}
                sx={{
                  p: 3,
                  color: category ? "text" : "background",
                  bg: (t) =>
                    category
                      ? "footer"
                      : serviceId === service.id
                      ? t.colors.dark[3]
                      : "primary",
                  borderBottom: category && "1px solid white",
                }}
              >
                <Heading
                  as="h4"
                  sx={{
                    fontSize: 2,
                    textTransform: "uppercase",
                    borderBottom:
                      !category &&
                      serviceId !== service.id &&
                      "1px solid white",
                    textAlign: "left",
                    display: "grid",
                    gridTemplateColumns: "1fr 20px",
                  }}
                  onClick={() => handleClickService(service.id)}
                >
                  {service.title}

                  {serviceId !== service.id ? <DownArrow /> : <RightArrow />}
                </Heading>
              </div>
              <div
                sx={{
                  bg: "background",
                  px: 3,
                  display: serviceId !== service.id && "none",
                  color: "header",
                }}
              >
                {description}
              </div>
            </div>
          </Fragment>
        );
      })}
      <div
        sx={{
          display: ["none", "grid"],
          gridTemplateColumns: "252px 1fr",
          maxHeight: ["512px"],
        }}
      >
        <div
          sx={{
            maxHeight: "512px",
            py: 1,
            bg: "transparent",
            display: "grid",
            placeItems: "center",
            color: "background",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              bg: "transparent",
              width: [null, "10px", "10px", "12px"],
              borderRadius: [null, null, "40px", "20px"],
              border: "3px solid primary",
            },
            "&::-webkit-scrollbar-thumb": {
              bg: "header",
              borderRadius: [null, "62px", "40px", "20px"],
            },
            "&::-webkit-scrollbar-track": {
              bg: "transparent",
            },
          }}
        >
          <div>
            {services.map((service) => {
              return (
                <Fragment key={service.id}>
                  <div
                    sx={{
                      textAlign: "center",
                      px: [3, 3],
                      mb: 2,
                    }}
                  >
                    <Heading
                      as="h4"
                      sx={{
                        fontSize: 2,
                        fontWeight: "heading",
                        p: 3,
                        bg: serviceId === service.id ? "primary" : "footer",
                        color: serviceId === service.id ? "background" : "text",
                        textTransform: "uppercase",
                        cursor: "pointer",
                      }}
                      onClick={() => handleClickService(service.id)}
                    >
                      {service.title}
                    </Heading>
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
        <div sx={{ width: "100%" }}>
          <div
            sx={{
              height: "100%",
              px: 3,
              py: 3,
              display: "grid",
              placeItems: "center",
            }}
          >
            {services.map((service) => {
              const description = documentToReactComponents(
                service.description.json
              );
              return (
                <Fragment key={service.id}>
                  {serviceId === service.id && (
                    <div
                      sx={{
                        display: "grid",
                        gridTemplateColumns: [null, null, "2fr 350px"],
                        maxWidth: "960px",
                        gap: 3,
                      }}
                    >
                      <div
                        sx={{
                          px: [null, null, 4, 5],
                          fontSize: [null, null, null, null, 3],
                          maxHeight: [null, null, null, "512px"],
                          pb: [3],
                          overflowY: "scroll",
                          "&::-webkit-scrollbar": {
                            bg: "transparent",
                            width: [null, "10px", "10px", "12px"],
                            borderRadius: [null, null, "40px", "20px"],
                            border: "3px solid primary",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            bg: "primary",
                            borderRadius: [null, "62px", "40px", "20px"],
                          },
                          "&::-webkit-scrollbar-track": {
                            bg: "transparent",
                          },
                        }}
                      >
                        {description}
                      </div>
                      {service.icon && (
                        <div sx={{ my: "auto" }}>
                          <Img
                            fluid={service.icon.fluid}
                            sx={{
                              display: ["none", null, "block"],
                              height: "350px",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
