/**@jsx jsx  */
import { Fragment, useContext } from "react";
import { Heading, jsx } from "theme-ui";
import { AppContext } from "../../context/app-context";
import { LocationContext } from "../../context/location-context";
import { DownArrow, RightArrow } from "../../icons";
import { ServiceComponent } from "./service-component";

export function ServiceCategory({
  categories,
  handleClickCategory,
  handleClickService,
}) {
  const { serviceCategoryRef } = useContext(LocationContext);
  const { serviceId, categoryId } = useContext(AppContext);

  return (
    <Fragment>
      <div sx={{ display: ["block", "none"] }}>
        {categories.map((category) => (
          <Fragment key={category.id}>
            <div
              id={category.slug}
              sx={{ p: 3, bg: "footer", borderBottom: "1px solid white" }}
            >
              <Heading
                as="h3"
                sx={{
                  fontSize: 2,
                  textTransform: "uppercase",
                  display: "grid",
                  gridTemplateColumns: "1fr 20px",
                }}
                onClick={() => handleClickCategory(category.id)}
              >
                {category.title}
                {categoryId !== category.id ? <DownArrow /> : <RightArrow />}
              </Heading>
            </div>
            <div
              sx={{
                textAlign: "left",
                display: categoryId !== category.id && "none",
              }}
            >
              {categoryId === category.id && (
                <ServiceComponent
                  services={category.services}
                  handleClickService={handleClickService}
                />
              )}
            </div>
          </Fragment>
        ))}
      </div>
      <div
        sx={{
          display: ["none", "flex"],
          bg: "footer",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          mt: [null, "-4em"],
          pt: [null, "4em"],
        }}
        ref={serviceCategoryRef}
        id="category"
      >
        {categories.map((category) => (
          <Fragment key={category.id}>
            <div>
              <Heading
                as="h3"
                onClick={() => handleClickCategory(category.id)}
                sx={{
                  fontSize: [null, null, null, null, 3],
                  fontWeight: "heading",
                  color: categoryId !== category.id ? "text" : "primary",
                  textTransform: "uppercase",
                  mx: "auto",
                  cursor: "pointer",
                  px: 2,
                  py: 3,
                  "&:hover": {
                    color: "primary",
                  },
                }}
              >
                {category.title}
              </Heading>
            </div>
            <div></div>
          </Fragment>
        ))}
      </div>
      <div sx={{ display: ["none", "grid"] }}>
        {categories.map(
          (category) =>
            categoryId === category.id && (
              <ServiceComponent
                services={category.services}
                key={category.id}
                handleClickService={handleClickService}
              />
            )
        )}
      </div>
    </Fragment>
  );
}
