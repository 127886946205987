/**@jsx jsx */
import { jsx } from "theme-ui";
import { useContext, useEffect } from "react";
import { graphql } from "gatsby";

import { Layout } from "../components/layout/main-layout";
import { Hero } from "../components/distribution/hero";
import { ServiceCategory } from "../components/service-component/service-category";
import { AppContext } from "../context/app-context";
import { Seo } from "../components/seo/seo";
import { LocationContext } from "../context/location-context";

export default function ServiceTemplate({ data, pageContext, location }) {
  const lang = pageContext.locale === "es-PE" ? "es" : "en";
  const {
    title,
    metaImage,
    service_category,
    services,
    slug,
  } = data.contentfulServiceCategory;

  const { categoryId, setCategoryId, setServiceId } = useContext(AppContext);
  const { serviceCategoryRef } = useContext(LocationContext);

  const categories =
    services && service_category
      ? [
          { id: "1", title: title, slug: slug, services: services },
          ...service_category,
        ]
      : !services && service_category
      ? [...service_category]
      : [{ id: "1", title: title, slug: slug, services: services }];

  const handleClickCategory = (id) => {
    if (window.innerWidth < 600 && categories && categories.length > 1) {
      service_category && categoryId && categoryId === id
        ? setCategoryId(null)
        : setCategoryId(id);
    } else {
      setCategoryId(id);
      const category = categories.filter((category) => category.id === id);
      setServiceId(category[0].services[0].id);
    }
  };
  const handleClickService = (id) => {
    setServiceId(id);
  };
  useEffect(() => {
    if (location.hash === "#category") {
      setTimeout(() => {
        window.scrollTo({
          top: serviceCategoryRef.current.offsetTop,
          behavior: "smooth",
        });
      }, 300);
    }
  }, []);

  useEffect(() => {
    const itemFound = categories.findIndex(
      (category) => category.id === categoryId
    );
    if (itemFound === -1) {
      setCategoryId(categories[0].id);
      setServiceId(categories[0].services[0].id);
    }
  }, []);

  return (
    <Layout>
      <Seo
        title={title}
        image={
          metaImage &&
          metaImage.fluid && {
            src: metaImage.fluid.src,
            height: 300,
            width: 300,
          }
        }
        lang={lang}
      />
      <Hero image={metaImage?.fluid} title={title} />
      <div>
        {categories && (
          <ServiceCategory
            categories={categories}
            handleClickCategory={handleClickCategory}
            handleClickService={handleClickService}
          />
        )}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($locale: String, $slug: String) {
    contentfulServiceCategory(
      node_locale: { eq: $locale }
      slug: { eq: $slug }
    ) {
      title
      slug
      metaImage {
        fluid(maxWidth: 2500, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      promoText {
        promoText
      }
      description {
        description
      }
      service_category {
        id
        title
        slug
        services {
          id
          title
          slug
          icon {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            json
          }
        }
      }
      services {
        id
        title
        slug
        icon {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        description {
          json
        }
      }
    }
  }
`;
