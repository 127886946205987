/**@jsx jsx */
import { Button, jsx, Heading, useThemeUI } from "theme-ui";
import { ReactNode, useContext, useEffect, useState } from "react";
import Img, { FluidObject } from "gatsby-image";

import { Dots, SAPIcon } from "../../icons";
import { Link } from "gatsby";
import { AppContext } from "../../context/app-context";

interface HeroProps {
  image: FluidObject;
  title: string;
  description?: string | ReactNode;
  callToAction?: { action: () => void; text: string };
  slug?: string;
}

export function Hero({
  image,
  title,
  description,
  callToAction,
  slug,
}: HeroProps) {
  const imageFix = ["MESA DE AYUDA", "HELP DESK", "SAP R3"];
  const { locale } = useContext(AppContext);
  const servicesPath = locale === "es-PE" ? "servicios" : "services";

  const imageFixBoolean = imageFix.includes(title.toUpperCase());

  const capacitaciones = ["CAPACITACIONES", "FORMATION"];
  const capacitacionesBoolean = capacitaciones.includes(title.toUpperCase());

  const [showButton, setShowButton] = useState(null);

  useEffect(() => {
    if (window) {
      const home =
        window.location.pathname.match(/^\/es\/$/) ||
        window.location.pathname.match(/^\/en\/$/) ||
        window.location.pathname.match(/^\/en$/) ||
        window.location.pathname.match(/^\/es$/);
      if (home !== null) {
        setShowButton(true);
      }
    }
  }, []);

  const {
    theme: { colors },
  } = useThemeUI();

  return (
    <div
      sx={{
        position: ["static", "relative"],
        height: "100%",
      }}
    >
      <div
        sx={{
          position: "relative",
          height: [null, "100%"],
        }}
      >
        <div
          sx={{
            display: ["none", "block"],
            width: "100%",
            height: "100%",
            bg: "header",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 80,
            opacity: 0.5,
          }}
        ></div>
        <span
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            color: "primary",
            zIndex: 200,
            width: "100%",
            height: "58px",
            display: ["none", "flex"],

            alignItems: "flex-end",
          }}
        >
          <Dots />
          <Dots />
          <Dots />
        </span>
        <Img
          fluid={image}
          backgroundColor={colors.blue[2]}
          sx={{
            height: "100%",
            "& > div": {
              pb: [
                "100%!important",
                "56.26%!important",
                null,
                "35.55%!important",
              ],
            },
            "& > picture > img": {
              objectPosition: [
                capacitacionesBoolean && "60% center!important",
                null,
                null,
                imageFixBoolean ? "0 65%!important" : "0% 25%!important",
              ],
            },
          }}
        />
        {/*  <span
          sx={{
            position: "absolute",
            bottom: [2, 5],
            left: [2, 5],
            width: ["92px", "144px"],
            zIndex: 100,
          }}
        >
          <SAPIcon />
        </span> */}
      </div>
      <div
        sx={{
          bottom: [null, 5],
          p: 3,
          position: ["static", "absolute"],
          zIndex: 100,
          color: ["header", "background"],
          width: [null, "400px", "512px"],
          right: [null, 5],
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Heading
          as="h1"
          sx={{
            fontSize: [3, 4, null, null, 5],
            textAlign: ["center", "left"],
            color: "inherit",
            textTransform: "uppercase",
          }}
        >
          {title}
        </Heading>
        {description && (
          <p
            sx={{
              display: ["block"],
              lineHeight: "body",
              color: "inherit",
              fontSize: [null, null, null, null, 3],
              m: 0,
              textAlign: "left",
              height: ["72px", "auto"],
              overflow: "hidden",
            }}
          >
            {description}
          </p>
        )}
        {showButton && (
          <Link
            to={`${servicesPath}/${slug}/#category`}
            onClick={() => {}}
            sx={{
              width: "max-content",
              fontFamily: "heading",
              lineHeight: "body",
              p: 3,
              color: "background",
              bg: "primary",
              fontWeight: "heading",
              textTransform: "uppercase",
              borderRadius: "24px",
              textDecoration: "none",
              mt: 2,
              alignSelf: ["center", "flex-start"],
              "&:hover": {
                bg: "header",
                color: "background",
              },
            }}
          >
            {locale === "es-PE" ? "Nuestra Oferta" : "Our Offer"}
          </Link>
        )}
      </div>
    </div>
  );
}
